"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Typography,
  Stack,
  styled,
  Chip,
} from "@mui/material";

import { GridExpandMoreIcon } from "@mui/x-data-grid";
import useHistoryList from "@/hooks/fetchers/useHistoryList";
import numeral from "numeral";
import moment from "moment";
import { Icon } from "@iconify/react";

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  background: theme.palette.background.default,
  padding: theme.spacing(2),
  fontSize: 14,
  textAlign: "center",
  borderRadius: theme.spacing(2),
}));

const CreatedAt = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  textAlign: "left",
}));

const Status = styled(Chip)(({ theme }) => ({
  fontSize: 12,
  textAlign: "center",
}));

export default function HistoryForm() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  const parseStatus = (status: any) => {
    switch (status) {
      case "confirmed":
        return {
          color: "#3DFB35",
          value: "สำเร็จ",
        };
      case "success":
        return {
          color: "#3DFB35",
          value: "สำเร็จ",
        };
      case "newrecord":
        return {
          color: "#f9ad48",
          value: "รอทำรายการ",
        };
      case "cancel":
        return {
          color: "#f13939",
          value: "ยกเลิก",
        };
      default:
        return {
          color: "orange.300",
          value: "ไม่พบสถานะ",
        };
    }
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChangeTab =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { data: histories } = useHistoryList();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          variant="fullWidth"
        >
          <Tab label="ฝาก" sx={{ color: (theme) => theme.gradient[900] }}/>
          <Tab label="ถอน" sx={{ color: (theme) => theme.gradient[900] }}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {histories?.deposits.length > 0 ? (
          histories?.deposits?.map((item: any, key: any) => {
            const status = parseStatus("confirmed");
            return (
              <Accordion
                key={key}
                sx={{ width: "100%" }}
                expanded={expanded === `${item?.id}`}
                onChange={handleChangeTab(`${item?.id}`)}
              >
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls={`${item?.id}bh-content`}
                  id={`${item?.id}bh-header`}
                >
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                    width="100%"
                  >
                    <Stack>
                      <Box sx={{ display: "flex" }} textAlign="left">
                        <Typography color={(theme) => theme.gradient[900]}>ฝาก</Typography>
                        <Typography sx={{ ml: 1 }} color={status.color}>
                          {numeral(item.amount).format("0,00.00")} ฿
                        </Typography>
                      </Box>
                      <Box>
                        <CreatedAt>
                          {moment(item.created_at)
                            .add("543", "years")
                            .format("DD/MM/YYYY HH:mm:ss")}
                        </CreatedAt>
                      </Box>
                    </Stack>
                    <Status
                      label={status.value}
                      color={"success"}
                      icon={<Icon icon="material-symbols:check" height={20} />}
                    />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Description>
                    {item?.action_type == "auto"
                      ? "ฝากเงินอัตโนมัติ"
                      : "ฝากโดยแอดมิน"}
                    {item?.description && <Box>{item?.description}</Box>}
                  </Description>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Typography align="center" color={(theme) => theme.gradient[900]}>ไม่พบข้อมูล</Typography>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {histories?.withdraws.length > 0 ? (
          histories?.withdraws?.map((item: any, key: any) => {
            const status = parseStatus(item.status);
            return (
              <Accordion
                key={key}
                sx={{ width: "100%" }}
                expanded={expanded === `${item?.id}`}
                onChange={handleChangeTab(`${item?.id}`)}
              >
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls={`${item?.id}bh-content`}
                  id={`${item?.id}bh-header`}
                >
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                    width="100%"
                  >
                    <Stack>
                      <Box sx={{ display: "flex" }} textAlign="left">
                        <Typography>ถอน</Typography>
                        <Typography sx={{ ml: 1 }} color={status.color}>
                          {numeral(item.amount).format("0,00.00")} ฿
                        </Typography>
                      </Box>
                      <Box>
                        <CreatedAt>
                          {moment(item.created_at)
                            .add("543", "years")
                            .format("DD/MM/YYYY HH:mm:ss")}
                        </CreatedAt>
                      </Box>
                    </Stack>
                    <Status
                      label={status.value}
                      color={"success"}
                      sx={{
                        backgroundColor: status.color,
                      }}
                      {...(item.status === "success" && {
                        icon: (
                          <Icon icon="material-symbols:check" height={20} />
                        ),
                      })}
                    />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Description>ไม่พบรายละเอียด</Description>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Typography align="center" color={(theme) => theme.gradient[900]}> ไม่พบข้อมูล</Typography>
        )}
      </CustomTabPanel>
    </Box>
  );
}

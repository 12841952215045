import React, { useState } from "react";

// mui
import { DialogTitle, Grid, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/Button";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

// mui icon
import CloseIcon from "@mui/icons-material/Close";

// type
import { ApiResponseCoupon } from "@/@types/coupon";
import { Box, CardMedia, TextField } from "@mui/material";

// form
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// fetch
import fetcher from "@/libs/fetcher";
import { enqueueSnackbar } from "notistack";
import { useAppModalStore } from "@/stores/appModalStore";
import useMe from "@/hooks/fetchers/useMe";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import { LoadingButton } from "@mui/lab";
import useCouponList from "@/hooks/fetchers/useCouponList";
import { styled } from "@mui/material/styles";
type Inputs = {
  coupon_code: string;
};
const schema = yup.object().shape({
  coupon_code: yup.string().required(),
});
const defaultValues = {
  coupon_code: "",
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function Mission() {
  const { refresh } = useMe();
  const { refresh: refreshPromotion } = usePromotionHistory();
  const [loading, setLoading] = React.useState(false);
  const [setOpen] = useAppModalStore((state) => [state.setOpen]);
  const [openCoupon, setOpenCoupon] = useState(false);
  const [couponName, setCouponName] = useState("");
  const [couponDesc, setCouponDesc] = useState("");
  const { data: couponList } = useCouponList();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: Inputs) => {
    setLoading(true);
    const { coupon_code } = data;
    const payload = {
      code: coupon_code,
    };
    const response: ApiResponseCoupon = await fetcher(
      "/api/v1/promotion/receive-coupon",
      { method: "POST", body: payload }
    );
    const status = !response?.status ? "warning" : "success";
    if (!response?.status) {
      setError("coupon_code", {
        type: "manual",
        message: `${response?.msg}`,
      });
      setLoading(false);
    } else {
      enqueueSnackbar("รับคูปองสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      refresh();
      refreshPromotion();
      setOpen(false);
      setLoading(false);
    }
  };

  function showDialog(id: string, name: string, description: string) {
    setCouponName(name);
    setCouponDesc(description);
    setOpenCoupon(true);
  }

  const handleClose = () => {
    setOpenCoupon(false);
  };

  return (
    <Stack spacing={2}>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)} width={"100%"}>
        <Card sx={{ mb: 1, p: 0, m: 0 }}>
          <CardContent>
            <Typography sx={{ textAlign: "center", mb: 2 }}>
              {/* กรอกรหัสคูปอง */}
            </Typography>
            <FormControl fullWidth>
              <Controller
                name="coupon_code"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    id="coupon_code"
                    label="ป้อนรหัสคูปอง"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    error={Boolean(errors.coupon_code)}
                    placeholder="กรุณากรอกโค้ด"
                    sx={{ mb: 2, color: (theme) => theme.gradient[900] }}
                  />
                )}
              />
              {errors.coupon_code && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.coupon_code.message}
                </FormHelperText>
              )}
            </FormControl>
            <LoadingButton
              loading={loading}
              disableRipple
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                height: "50px",
              }}
            >
              ใช้งานคูปอง
            </LoadingButton>
          </CardContent>
        </Card>
      </Box>
      <Box>
        {couponList?.map((item, key) => (
          <Grid key={key} item sx={{ p: 0 }} xs={1} sm={4} md={4}>
            <Card>
              <CardMedia component="img" image={item?.img} alt={item?.name} />
              <CardContent>
                <Box textAlign="center">
                  <Typography gutterBottom sx={{color: (theme) => theme.gradient[900]}}>{item?.name}</Typography>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() =>
                      showDialog(item?.id, item?.name, item?.description)
                    }
                  >
                    รายละเอียด
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openCoupon}
        >
          <DialogTitle sx={{ m: 0, p: 2, color:(theme) => theme.gradient[900] }} id="customized-dialog-title">
            {couponName}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography gutterBottom color={(theme) => theme.gradient[900]}>{couponDesc}</Typography>
          </DialogContent>
        </BootstrapDialog>
      </Box>
    </Stack>
  );
}

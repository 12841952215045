import { Logo } from "@/components/logo";
import { useUserProfile } from "@/hooks";
import usePlatformWallets, {
  PlatformWalletItem,
} from "@/hooks/usePlatformWallets";
import useTransferWallet from "@/hooks/useTransferWallet";
import { formatMoney } from "@/libs/common";
import { useConfigStore } from "@/stores/configStore";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Divider,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { green, red } from "@mui/material/colors";
import { shallow } from "zustand/shallow";

type TWalletItem = { name: string; balance: number; logo: string };

const WalletItem = ({ item }: { item: TWalletItem }) => {
  const theme = useTheme();
  const { user } = useUserProfile();
  const { handleTransfer, loading } = useTransferWallet();
  const userBalance = user?.balance || 0;

  return (
    <>
      <ListItem
        secondaryAction={
          <Box display="flex" gap={2}>
            <LoadingButton
              disabled={userBalance <= 0}
              loading={loading}
              onClick={() =>
                handleTransfer("IN", "main", item.name.toLowerCase())
              }
              variant="contained"
              color="success"
              sx={{
                bgcolor: green[800],
                color: theme.palette.getContrastText(green[800]),
              }}
            >
              โยกเงินเข้า
            </LoadingButton>
            <LoadingButton
              disabled={item.balance <= 0}
              loading={loading}
              onClick={() =>
                handleTransfer("OUT", item.name.toLowerCase(), "main")
              }
              variant="contained"
              color="error"
              sx={{
                bgcolor: red[800],
                color: theme.palette.getContrastText(red[800]),
              }}
            >
              โยกเงินออก
            </LoadingButton>
          </Box>
        }
      >
        <ListItemIcon>
          <Avatar
            alt={item.name}
            src={item.logo}
            sx={{ width: 48, height: 48 }}
          />
        </ListItemIcon>
        <Stack justifyContent={"center"}>
          <Typography variant="caption" color={alpha("#ffffff", 0.5)}>
            {item.name}
          </Typography>
          <Typography variant="body1">{formatMoney(item.balance)}</Typography>
        </Stack>
      </ListItem>
      <Divider sx={{ borderColor: alpha("#ffffff", 0.15) }} />
    </>
  );
};

const WalletsList = ({ items }: { items: PlatformWalletItem[] }) => {
  return (
    <List>
      {items.map((item, index) => {
        return <WalletItem key={index} item={item} />;
      })}
    </List>
  );
};

const CardMainWallet = ({
  logo,
  balance,
}: {
  logo?: string;
  balance: number;
}) => {
  const theme = useTheme();
  return (
    <Stack
      py={2}
      px={2}
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      sx={{
        bgcolor: theme.mineColor["paper-soft"],
      }}
    >
      {logo && (
        <Box mb={0.5}>
          <Logo src={logo} height={theme.config.logo.height} />
        </Box>
      )}
      <Typography
        variant="body1"
        color={alpha(
          theme.palette.getContrastText(theme.mineColor["paper-soft"]),
          0.5
        )}
      >
        กระเป๋าหลัก
      </Typography>
      <Typography
        variant="h5"
        fontWeight={"bold"}
        color={alpha(
          theme.palette.getContrastText(theme.mineColor["paper-soft"]),
          1
        )}
      >
        {formatMoney(balance)}
      </Typography>
    </Stack>
  );
};

const Wallets = () => {
  const [config] = useConfigStore((state) => [state.config], shallow);
  const { user } = useUserProfile();
  const { items } = usePlatformWallets();
  return (
    <Stack>
      <CardMainWallet
        logo={config?.logo}
        balance={Number(user?.balance || 0)}
      />
      <WalletsList items={items} />
    </Stack>
  );
};

export default Wallets;

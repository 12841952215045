import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";

type Props = {
  onClick: () => void;
};

const CloseButton = ({ onClick }: Props) => {
  return (
    <Box
      sx={{
        position: "absolute",
      }}
      right={6}
      top={6}
    >
      <IconButton onClick={onClick}>
        <Close />
      </IconButton>
    </Box>
  );
};

export default CloseButton;

import React from "react";
import BannerCarousel from "../components/carousel/BannerCarousel";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import Dashboard from "./Dashboard";

type Props = {};

const Content = (props: Props) => {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  return (
    <>
      {!isLoggedIn && <BannerCarousel />}
      {isLoggedIn && <Dashboard />}
    </>
  );
};

export default Content;

import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

type SiteSetting = {
  lineOaUrl: string;
};

export default function useSiteSetting(): SiteSetting {
  const [config] = useConfigStore((state) => [state.config], shallow);
  return {
    lineOaUrl: config?.line_oa_url ?? "",
  };
}

import { Box } from "@mui/material";
import React from "react";
import HistoriesList from "./HistoriesList";

type Props = {};

const Histories = (props: Props) => {
  return (
    <Box width="100%">
      <HistoriesList />
    </Box>
  );
};

export default Histories;

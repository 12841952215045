import authConfig from "@/configs/auth";
import { getApiKeyHeaders } from "@/libs/utils";
import { ApiKeyHeaders } from "@/@types/util";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
  loading: false | true;
  errMessage?: string;
};

type Actions = {
  accept: (id: string) => Promise<void>;
  setErrMessage?: (errMessage: string) => void;
};

export const usePromotionStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      accessToken: null,
      user: null,
      loading: false,
      accept: async (id) => {
        set((state) => ({ loading: true }));
        try {
          const accessToken = window.localStorage.getItem(
                authConfig.storageTokenKeyName
          );
          if (accessToken) {
            const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
            const response = await fetch('/api/v1/promotion/receive', {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                agent: xApiKeyHeaders.agent,
                time: xApiKeyHeaders.time.toString(),
                "X-Api-Key": xApiKeyHeaders["X-Api-Key"],
                Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ promotion_id: id }),
            });
            const data = await response.json();
            if (data.status) {
                return data.data;
            } else {
                set((state) => ({ errMessage: data.message, loading: false }));
                return { errMessage: data.msg };
            }
          }
        } finally {
          set((state) => ({ loading: false }));
        }
        
      }
    }))
  ),
  Object.is
);
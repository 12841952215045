'use client'

import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { formatToThaiDateTime } from "@/libs/common";

interface ICountdownTimerProps {
  time: number;
  setExpired: any;
}

export default function CountdownTimer(props: ICountdownTimerProps) {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const closeTime = new Date(props.time).getTime();
      const distance = closeTime - now;

      if (distance < 0) {
        clearInterval(interval);
        props.setExpired(true)
        // setTimeLeft("หมดอายุ");
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // setTimeLeft(`${days} วัน · ${hours} ช.ม · ${minutes} นาที · ${seconds} วิ`);
        setTimeLeft(`${minutes} นาที · ${seconds} วินาที`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [props.time]);

  return timeLeft === 'หมดอายุ'
    ? (
      <Typography
        variant="h6"
        fontWeight={"900"}
        sx={{
          backgroundColor: '#e11d48',
          padding: '0.5rem',
          borderRadius: '0.25rem'
        }}
      >
        {timeLeft}
      </Typography>
    ) : (
      <Typography variant="h6" fontWeight={"900"}>
        {timeLeft}
      </Typography>
    );
}

import { BoxOption } from "@/@types/myster-box";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
  results: BoxOption[];
  isFinished: boolean;
  isLoading: boolean;
  selectBoxId: number | null;
  selectBoxValue: number | null;
};

type Actions = {
  setResults: (results: BoxOption[]) => void;
  setIsFinished: () => void;
  setIsLoading: (value: boolean) => void;
  setSelectBoxId: (id: number) => void;
  setSelectBoxValue: (value: number) => void;
};

export const useMysteryBoxStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      results: [],
      isFinished: false,
      isLoading: false,
      selectBoxId: null,
      selectBoxValue: 50,
      setResults: (results) => set(() => ({ results })),
      setIsFinished: () => set((state) => ({ isFinished: !state.isFinished })),
      setIsLoading: (value) => set((state) => ({ isLoading: value })),
      setSelectBoxId: (id) => set(() => ({ selectBoxId: id })),
      setSelectBoxValue: (value) => set(() => ({ selectBoxValue: value })),
    }))
  ),
  Object.is
);

import CopyButton from "@/components/ui/CopyButton";
import { useAuthStore } from "@/stores/authStore";
import useBankDeposit from "@/hooks/fetchers/useBankDeposit";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { blue, green, grey, orange, purple } from "@mui/material/colors";
import Image from "next/image";
import React from "react";
import { shallow } from "zustand/shallow";

type Props = {};

const detailOfBanks: {
  [key: string]: {
    name: string;
    color: string;
  };
} = {
  SCB: {
    name: "ธนาคารไทยพาณิชย์",
    color: purple["800"],
  },
  KBANK: {
    name: "ธนาคารกสิกรไทย",
    color: green["800"],
  },
  TMN: {
    name: "ทรูมันนี่วอเลท",
    color: orange["800"],
  },
  KK: {
    name: "ธนาคารเกียรตินาคินภัทร",
    color: blue["800"],
  }
};

const Deposit = (props: Props) => {
  const { data: depositBanks, isLoading } = useBankDeposit();
  const [user] = useAuthStore((state) => [state.user], shallow);
  return (
    <Box>
      <Divider>
        <Chip
          label="ฝากเงิน"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      <Stack>
        {depositBanks?.data?.map((bank, key) => (
          <Box
            key={key}
            bgcolor={detailOfBanks[bank.bank_code].color}
            p={2}
            borderRadius={1}
            mt={2}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                flex="1"
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Image
                  src={`/assets/bank-logo/${bank.bank_code}.png`}
                  width={100}
                  height={100}
                  alt={bank.bank_code}
                  style={{ borderRadius: "100%" }}
                />
              </Box>
              <Box display="flex" flexDirection={"column"} alignItems={"end"}>
                <Typography variant="button" sx={{ color: (theme) => theme.gradient[900] || grey[500] }} lineHeight={1}>
                  {detailOfBanks[bank.bank_code].name}
                </Typography>
                <Typography variant="h6" lineHeight={1.4} sx={{ color: (theme) => theme.gradient[900] }}>
                  {bank.bank_account_name}
                </Typography>
                <Typography variant="h6" lineHeight={1.2} sx={{ color: (theme) => theme.gradient[900] }}>
                  {bank.bank_account_no}
                </Typography>
                <CopyButton text={bank.bank_account_no} />
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>

      <Box mt={2}>
        <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#191207" }}>
          <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>คำเตือน</AlertTitle>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            บัญชีที่ใช้โอนเงินต้องตรงกับชื่อบัญชีที่ลงทะเบียนไว้เท่านั้น
          </Typography>
          <Stack>
            <Box borderRadius={1} mt={2}>
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  flex="1"
                  display={"flex"}
                  justifyContent={"start"}
                  alignItems={"center"}
                >
                  <Image
                    // src={`/assets/bank-logo/SCB.png`}
                    src={`${user?.bank_logo || ""}`}
                    width={80}
                    height={80}
                    alt={"SCB"}
                    style={{ borderRadius: "100%" }}
                  />
                </Box>
                <Box display="flex" flexDirection={"column"} alignItems={"end"}>
                  <Typography variant="button" sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : grey[500] }} lineHeight={1}>
                    {user?.bank_name}
                  </Typography>
                  <Typography variant="h6" lineHeight={1.4} sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                    {user?.fullname}
                  </Typography>
                  <Typography variant="h6" lineHeight={1.2} sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                    {user?.account_number}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Alert>
      </Box>
    </Box>
  );
};

export default Deposit;

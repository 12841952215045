"use client";

import { Box, Grid, CircularProgress, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";
import Slider from "react-slick";
import useBannerSlider from "@/hooks/fetchers/useBannerSlider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {};
/*
const banners = [
  {
    image:
      "https://sexygaming.me/media/cache/strip/202310/block/3973293622575b24c175c51edeab1d42.jpg",
    title: "Legend 1",
  },
  {
    image:
      "https://sexygaming.me/media/cache/strip/202304/block/9679cafa4c2686ab92b337dbf5d8dd1a.png",
    title: "Legend 2",
  },
  {
    image:
      "https://sexygaming.me/media/cache/strip/202304/block/ef08bd2fe1677945e5dab7a59559f7a3.png",
    title: "Legend 3",
  },
];
*/
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const indicatorStyles: CSSProperties = {
  background: "#fff",
  width: 32,
  height: 4,
  display: "inline-block",
  margin: "0 8px",
};

export default function BannerSlick({}: Props) {
  const theme = useTheme();
  const { data: bannersliders, isLoading: isBannerSliderLoading } =
    useBannerSlider();

  if (isBannerSliderLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Slider {...settings}>
        {bannersliders?.map((banner: any) => (
          <Box
            key={banner?.desc}
            sx={{
              boxShadow: theme.shadows[3],
              mx: 2,
            }}
          >
            <img src={banner?.image} />
          </Box>
        ))}
      </Slider>
    );
  }
}

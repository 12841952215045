"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useRouter } from "next/navigation";
import { Send } from "@mui/icons-material";
import { useChangePasswordStore } from "@/stores/changePasswordStore";
import { useSnackbar } from "notistack";
import { useAppModalStore } from "@/stores/appModalStore";

type Inputs = {
  password?: string;
  password_new?: string;
  password_confirm?: string;
};

const schema = yup.object().shape({
  // current_password: yup
  // .typeError("กรุณากรอกจำนวนเงินที่ต้องการถอน")
  // .required("กรุณากรอกจำนวนเงินที่ต้องการถอน"),
  password: yup.string().optional(),
  password_new: yup.string().optional(),
  password_confirm: yup.string().optional(),
});

export default function ChangePasswordForm() {
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const [changepassword] = useChangePasswordStore(
    (state) => [state.changepassword],
    shallow
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { password, password_new, password_confirm } = data;
    // console.log("password", password);
    // console.log("password_new", password_new);
    // console.log("password_confirm", password_confirm);
    changepassword(password, password_new, password_confirm)
      .then((res: any) => {
        if (res?.errMessage) {
          enqueueSnackbar(res.errMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          enqueueSnackbar("สำเร็จ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          setOpen(false);
        }
      })
      .catch((err) => {
        // "This is a success message!"
        enqueueSnackbar(err.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur } }) => (
                <TextField
                  id="password"
                  autoFocus
                  type="password"
                  label="รหัสผ่านปัจจุบัน"
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.password)}
                  required
                  placeholder="รหัสผ่านปัจจุบัน"
                />
              )}
            />
            {errors.password && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.password.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="password_new"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur } }) => (
                <TextField
                  id="password_new"
                  type="password"
                  label="รหัสผ่านใหม่"
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.password_new)}
                  required
                  placeholder="รหัสผ่านใหม่"
                />
              )}
            />
            {errors.password_new && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.password_new.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Controller
              name="password_confirm"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur } }) => (
                <TextField
                  id="password_confirm"
                  type="password"
                  label="ยืนยันรหัสผ่านใหม่"
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(errors.password_confirm)}
                  required
                  placeholder="ยืนยันรหัสผ่านใหม่"
                />
              )}
            />
            {errors.password_confirm && (
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.password_confirm.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            style={{ height: "50px" }}
          >
            ยืนยัน
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

import * as React from "react";
import { CardUserProfile } from "@/components/card";
import { useUserProfile } from "@/hooks";
import { useTheme } from "@mui/material";

export default function UserWallet() {
  const {
    user,
    isLoading,
    currentBalance,
    isBonus,
    promotionHistory,
    isPromotionHistoryLoading,
    handleOpenModal,
    handleRemovePro,
  } = useUserProfile();
  const theme = useTheme();
  return (
    <CardUserProfile
      loading={isLoading}
      username={user?.username || "Loading"}
      currentBalance={currentBalance}
      isBonus={isBonus}
      isPromotionHistoryLoading={isPromotionHistoryLoading}
      promotionHistory={promotionHistory}
      showFooter={theme.config.showDepositAndWithdrawInWallet}
      onRemovePro={handleRemovePro}
      onOpenModal={handleOpenModal}
    />
  );
}

import { Box, Theme, alpha, styled } from "@mui/material";
import { BoxProps } from "@mui/material/Box";
import { grey } from "@mui/material/colors";

type Props = BoxProps & {
  isbonus: string;
};

export const StyledCardUserProfile = styled(Box)<Props>(
  ({ theme, isbonus }) =>
    () => ({
      background: theme.gradient[100],
      boxShadow: theme.shadows[8],
      textAlign: "center",
      color: theme.palette.text.secondary,
      "& #text-username": {
        color: theme.palette.primary.contrastText,
        ...((
          theme.name === "preset3" ||
          theme.name === "preset6" ||
          theme.name === "preset13" ||
          theme.name === "preset15" ||
          theme.name === "preset16"
        ) && {
          color: theme.palette.primary.main,
          textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
        }),
        ...(theme.name === "preset5" && {
          color: 'rgb(216 208 222)',
          textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
        }),
      },
      "& #text-balance-label": {
        color: theme.palette.primary.contrastText,
        ...((
          theme.name === "preset3" ||
          theme.name === "preset6" ||
          theme.name === "preset13" ||
          theme.name === "preset15" ||
          theme.name === "preset16"
        ) && {
          color: grey[300],
          textShadow: "1px 2px 2px rgba(0, 0, 0, 0.5)",
        }),
        ...(theme.name === "preset5" && {
          color: 'rgb(216 208 222)',
          textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
        }),
      },
      "& #text-balance-value": {
        color: theme.palette.primary.contrastText,
        ...((
          theme.name === "preset3" ||
          theme.name === "preset6" ||
          theme.name === "preset13" ||
          theme.name === "preset15" ||
          theme.name === "preset16"
        ) && {
          color: "white",
          textShadow: "1px 2px 2px rgba(0, 0, 0, 0.5)",
        }),
        ...(theme.name === "preset5" && {
          color: 'rgb(216 208 222)',
          textShadow: "1px 1px 4px rgba(0, 0, 0, 0.4)",
        }),
      },
      "& #profile-container": {
        padding: theme.spacing(2),
      },
      ...(isbonus == "true" && {
        background: theme.gradient[200],
        "& #text-username": {
          color: theme.palette.primary.contrastText,
        },
        "& #text-balance-label": {
          color: theme.palette.primary.contrastText,
        },
        "& #text-balance-value": {
          color: theme.palette.primary.contrastText,
        },
        "& #profile-container": {
          padding: theme.spacing(2),
        },
      }),
    })
);

import * as React from "react";
import Box from "@mui/material/Box";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { shallow } from "zustand/shallow";
import { Check, Send } from "@mui/icons-material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "next/navigation";
import { useAppModalStore } from "@/stores/appModalStore";
import { useForgotPasswordStore } from "@/stores/forgotPasswordStore";
import { useAuthModalStore } from "@/stores/authModalStore";

type Inputs = {
  password: string;
  confirmPassword: string;
};

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .max(30, "รหัสผ่านต้องมีไม่เกิน 30 ตัวอักษร")
    .required("กรุณากรอกรหัสผ่าน"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "รหัสผ่านไม่ตรงกัน")
    .required("กรุณายืนยันรหัสผ่าน"),
});

export default function FormVerifyPassword() {
  const route = useRouter();
  const [setOpenAppModal] = useAppModalStore(
    (state) => [state.setOpen],
    shallow
  );
  const { enqueueSnackbar } = useSnackbar();
  const [loading, input, updateInput, forgotPassword] = useForgotPasswordStore(
    (state) => [
      state.loading,
      state.input,
      state.updateInput,
      state.forgotPassword,
    ],
    shallow
  );
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );

  const [login] = useAuthStore((state) => [state.login], shallow);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const handleChangePassword = async (
    password: string,
    confirmPassword: string
  ) => {
    if (input) {
      const payload = {
        username: input.username!,
        bank: input.bankCode!,
        account_number: input.bankAccountNumber!,
        password: input.password! || password,
        password_confirmation: input.password_confirmation! || confirmPassword,
      };

      const { status, msg } = await forgotPassword(payload);
      if (!status) {
        enqueueSnackbar(msg, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        enqueueSnackbar(msg, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
      setOpenAppModal(false);
      setOpenLoginModal(true);


    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { password, confirmPassword } = data;
    await updateInput({ password, password_confirmation: confirmPassword });
    await handleChangePassword(password, confirmPassword);
  };

  return (
    <SnackbarProvider maxSnack={3}>
      <Box p={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    id="password"
                    label="รหัสผ่าน"
                    type="password"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.password)}
                    placeholder="รหัสผ่าน"
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.password.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    id="confirmPassword"
                    label="ยืนยันรหัสผ่าน"
                    type="password"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.confirmPassword)}
                    placeholder="ยืนยันรหัสผ่าน"
                  />
                )}
              />
              {errors.confirmPassword && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.confirmPassword.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <LoadingButton
              loading={loading}
              startIcon={<Check />}
              type="submit"
              variant="contained"
              fullWidth
              style={{ height: "50px" }}
            >
              ตั้งรหัสผ่านใหม่
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </SnackbarProvider>
  );
}

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, IconButton, Theme } from "@mui/material";
import Iframe from "react-iframe";
import { Icon } from "@iconify/react";
import { red } from "@mui/material/colors";
import styled from "@emotion/styled";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: true | false;
  close: () => void;
};

const CloseIconButton = styled(IconButton)(({ theme }: { theme?: Theme }) => ({
  backgroundColor: red[700],
  color: theme?.palette.common.white,
  boxShadow: theme?.shadows[4],
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    backgroundColor: red[900],
  },
  "&:active": {
    transform: "scale(0.98)",
    backgroundColor: red[800],
  },
}));

export default function GameLauncherDialog({ open, close }: Props) {
  const handleClose = () => {
    close();
  };
  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <Box height={"100%"} position="relative">
        <Box position="relative" height="100%">
          ภาพโปรโมท
        </Box>
      </Box>
    </Dialog>
  );
}

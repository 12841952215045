import * as React from "react";
import MainMenu from "./MainMenu";
import BannerCarousel from "../components/carousel/BannerCarousel";
import { UserWallet, GameMenu, DepositAndWithdrawButtonGroup } from "./";
import { LayoutDashboard } from "@/components/layout";

export default function Dashboard() {
  return (
    <LayoutDashboard
      wallet={<UserWallet />}
      bannerCarousel={<BannerCarousel />}
      gameCategoryMenu={<GameMenu />}
      mainMenu={<MainMenu />}
      depositAndWithdrawButtonGroup={<DepositAndWithdrawButtonGroup />}
    />
  );
}
BannerCarousel;

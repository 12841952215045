import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR, { mutate } from "swr";
import { shallow } from "zustand/shallow";

type wheelList = {
  id: string;
  agent_name: string;
  message: string;
  text: string;
  value: number;
};

type wheelListReturn = {
  refresh: () => void;
  data: wheelList | null;
  isLoading: boolean;
  isError: any;
};

export default function useWheelList(): wheelListReturn {
  const refresh = () => {
    mutate(`/api/v1/wheel/all`);
  };
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<wheelListReturn>(
    isLoggedIn ? `/api/v1/wheel/all` : null,
    fetcherAuth
  );
  return {
    refresh,
    data: data?.data || null,
    isLoading: !error && !data,
    isError: error,
  };
}

import authConfig from "@/configs/auth";
import { randomDelay } from "@/util/helper";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
  loading: false | true;
  error: string;
};

type Actions = {
  setLoading: (loading: false | true) => void;
  setError: (error: string) => void;
  transfer: (
    from: string,
    to: string,
    onSuccess: (message: string, data: any) => void,
    onError: (message: string) => void
  ) => void;
};

const requestTransferWallet = async (from: string, to: string) => {
  const accessToken = window.localStorage.getItem(
    authConfig.storageTokenKeyName
  );
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APP_API_LAUNCH_GAME}/api/v1/users/transfer-wallet`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        from,
        to,
      }),
    }
  );
  return response.json();
};

export const useTransferWalletStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      loading: false,
      error: "",
      setLoading: (loading) => {
        set((state) => {
          state.loading = loading;
        });
      },
      setError: (error) => {
        set((state) => {
          state.error = error;
        });
      },
      transfer: async (from, to, onSuccess, onError) => {
        set((state) => {
          state.loading = true;
        });
        requestTransferWallet(from, to)
          .then(async (res) => {
            if (res?.status === true) {
              await randomDelay();
              onSuccess && onSuccess(res.msg, res.data);
            } else {
              await randomDelay();
              set((state) => {
                state.error = res.msg;
              });
              onError && onError(res.msg);
            }
          })
          .catch(async (err) => {
            await randomDelay();
            set((state) => {
              state.error = err.message;
            });
            onError && onError(err.message);
          })
          .finally(() => {
            set((state) => {
              state.loading = false;
            });
          });
      },
    }))
  ),
  Object.is
);

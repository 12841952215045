import { fetcherNonAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";


type BannerSlider = {
  id: number;
  image: string;
  desc: string;
};

type UseBannerSliderReturn = {
  data: BannerSlider[] | null;
  isLoading: boolean;
  isError: any;
};



export default function useBannerSlider(): UseBannerSliderReturn {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<UseBannerSliderReturn>(`/api/v1/sliders/get-all`,
    fetcherNonAuth
  );

  return {
    data: data?.data || null,
    isLoading: !error && !data,
    isError: error,
  };
}

"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import numeral from "numeral";
import { useSnackbar } from "notistack";

// mui
import Grid from "@mui/material/Unstable_Grid2";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Typography,
  Alert,
  AlertTitle,
  Box,
  useTheme
} from "@mui/material";
import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";

// components
import TermsDialog from "@/sections/refund/TermsDialog";
import SnackbarWithIcon from "@/components/snack-bar/SnackbarWithIcon";

// fetch data
import fetcher from "@/libs/fetcher";

// types
import {
  CashbackData,
  ApiResponseCashback,
  ApiResponseClaimCashbackData,
} from "@/@types/refound";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import useMe from "@/hooks/fetchers/useMe";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";

import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

interface State extends SnackbarOrigin { }

const gameType = [
  {
    type: 'slot',
    label: 'สล็อต',
    icon: '/assets/icons/categories/slot_new.png'
  },
  {
    type: 'casino',
    label: 'คาสิโน',
    icon: '/assets/icons/categories/casino_new.png'
  },
  {
    type: 'sport',
    label: 'กีฬา',
    icon: '/assets/icons/categories/sport_new.png'
  },
  {
    type: 'lotto',
    label: 'หวย',
    icon: '/assets/icons/categories/huay_new.png'
  }, {
    type: 'fishing',
    label: 'ยิงปลา',
    icon: '/assets/icons/categories/fishing_new.png'
  },
]

const Cashback = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { refresh } = useMe();
  const { refresh: refreshPromotion } = usePromotionHistory();
  const [loading, setLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [state, setState] = useState<State>({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cashback, setCashback] = useState<number>(0);
  const [cashbackProfit, setcashBackProfit] = useState<string>("0");
  const [config] = useConfigStore((state) => [state.config], shallow);

  // const [cashbackDayofweek, setCashbackDayofweek] = useState<number>(0);
  // const [dayOfWeek, setDayOfWeek] = useState<number>(0);
  // const [dayText, setDayText] = useState<string>("");

  const [cashbackData, setCashbackData] = useState<any | null>(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("An error occurred!");
  const [severity, setSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("error");
  const fetchCashback = async (): Promise<void> => {
    setLoading(true);
    const response: ApiResponseCashback = await fetcher(
      "/api/v2/users/cashback"
    );
    const result = response.data;
    if (result) {
      setCashback(result?.cashback);
      setcashBackProfit(result?.cashback_profit);

      // setCashbackDayofweek(result?.cashback_dayofweek);
      // setDayOfWeek(result?.day_of_week);
      // setDayText(result?.day_text);
    }
    setCashbackData(result);
    setLoading(false);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const claimCashback = async () => {
    setClaimLoading(true)

    try {
      const response: ApiResponseClaimCashbackData = await fetcher(
        `/api/v2/users/claim-cashback`
      );
      setClaimLoading(false)

      if (response?.status !== true) {
        enqueueSnackbar(response?.msg || "พบข้อผิดพลาดจากระบบ กรุณาลองใหม่อีกครั้ง", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        refresh();
        refreshPromotion();
        setCashbackData((prevState: any) => ({
          ...prevState,
          haveReceived: true
        }))
        enqueueSnackbar("สำเร็จ", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      setClaimLoading(false)
      enqueueSnackbar("พบข้อผิดพลาดจากระบบ กรุณาลองใหม่อีกครั้ง", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    fetchCashback();
  }, []);
  if (loading) {
    return (
      <>
        <Grid sx={{ textAlign: "center", color: (theme) => theme.gradient[900] }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  }

  const cashbackType = cashbackData?.cashback_type === 'turnover' ? 'ยอดเล่น' : 'ยอดเสีย'

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
      <Box>
        <Divider>
          <Chip
            label={`คืน${cashbackType}`}
            color="primary"
            size="medium"
            style={{ fontSize: "18px", fontWeight: 700 }}
          />
        </Divider>
        <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
          <Box display="flex" flexDirection={"row"} alignItems={"center"}>
            <Typography variant="h6" fontWeight={"700"} lineHeight={1.4} align="center" sx={{ color: (theme) => theme.gradient[920] }} mr={1}>
              {`รับ${cashbackType}ทุกยอดการแทง`}
            </Typography>
            {/* <Typography variant="h6" fontWeight={"500"} align="center" color={(theme) => theme.gradient[920]} mr={1}>
              {`${cashbackProfit}%`}
            </Typography> */}
            {/* <Typography variant="h6" lineHeight={1.4} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
              ทุกยอดการแทง
            </Typography> */}
          </Box>
          {/* <Typography variant="subtitle2" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
            ระบบจะทำการคืนยอดเสีย ทุกวัน ตั้งแต่เวลา 00:00 น. เป็นเวลา 24 ชั่วโมง
          </Typography> */}
        </Box>
        <Box display="flex" flexDirection={"row"} alignItems={"center"} justifyContent={"space-around"} mt={2}>
          {
            gameType.map((data: any) => {
              const commissionType = cashbackData?.commissionList?.find((commission: any) => commission.type === data.type)

              return (
                <Box textAlign="center" key={data.type}>
                  <img
                    src={data.icon}
                    width="60"
                    loading="lazy"
                  />
                  <Typography align="center">{data.label}</Typography>
                  <Typography align="center">
                    {commissionType ? `${commissionType.cashback_profit}%` : "-%"}
                  </Typography>
                </Box>
              )
            })
          }
        </Box>
        <Stack justifyContent={"center"} alignItems={"center"} mt={2}>
          <Typography align="center" color={(theme) => theme.gradient[900]}>
            {`${cashbackType}สะสม`}
          </Typography>
          <Divider
            sx={{
              borderColor: theme.palette.primary.main,
              borderRadius: "10px",
              borderWidth: "1px",
              width: "20px",
            }}
          />
          <Typography
            align="center"
            fontSize="32px"
            fontWeight={"bold"}
            color={(theme) => theme.gradient[900] || theme.palette.primary.main}
          >
            {numeral(cashback).format("0,0.00")}
          </Typography>
        </Stack>
        <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
          <LoadingButton
            disabled={cashbackData?.haveReceived ? true : false}
            onClick={claimCashback}
            type="submit"
            variant="contained"
            fullWidth
            loading={claimLoading}
            sx={{
              fontSize: '1.5rem',
              fontWeight: 700,
              color: '#fff',
              background: cashbackData?.haveReceived
                ? 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, #6f7070 100%);'
                : 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
              height: "50px",
              "&:hover": {
                color: '#fff',
                background: cashbackData?.haveReceived
                  ? 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, #6f7070 100%);'
                  : 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
              },
            }}
          >
            {cashbackData?.haveReceived ? `ทำรายการสำเร็จแล้ว` : `รับ${cashbackType}`}
          </LoadingButton>
        </Box>
        <Box mt={2}>
          <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#191207" }}>
            <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              {`กฏการรับกิจกรรมการถอน “${cashbackType}”`}
            </AlertTitle>
            <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              1. ลูกค้าจะต้องไม่กดรับ โบนัส ทุกประเภท
            </Typography>
            <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
              {`2. กดรับคืน “${cashbackType}” ของยอดการแทงเมื่อวานได้ทุกวัน โดยระบบจะทำการคืน${cashbackType}ทุกวันตั้งแต่เวลา 00:00 น. เป็นเวลา 24 ชั่วโมง หากไม่กดรับยอดภายในวันนั้น ยอดดังกล่าวจะถูกยกเลิกทันที`}
            </Typography>
          </Alert>
        </Box>
      </Box>

      {/* <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center" }}>
          <Card>
            <CardActionArea onClick={claimCashback}>
              <CardContent component="div">
                <Typography sx={{ color: (theme) => theme.gradient[900] }}>ยอดเงินคืน</Typography>
                <SavingsOutlinedIcon
                  sx={{ fontSize: "3rem", color: (theme) => theme.gradient[900] }}
                ></SavingsOutlinedIcon>
                <Typography variant="h4" sx={{ textAlign: "center", color: (theme) => theme.gradient[900] }}>
                  {numeral(cashback).format("0,0.00")}
                </Typography>
                <Grid component="div">
                  <Chip sx={{ color: (theme) => theme.gradient[900] || "primary" }} label="ตรวจสอบยอดรับเสีย"></Chip>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid> */}

      {/* <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center",color: (theme) => theme.gradient[900] }}>
          <Typography>❗️ ยอดคืนสะสมจะรับได้ทุก{dayText} ❗️</Typography>
        </Grid>
      </Grid> */}

      {/* <Grid xs={12} sm={12} md={12}>
        <Grid sx={{ textAlign: "center" }}>
          <Card>
            <CardContent>
              <CurrencyExchangeOutlinedIcon
                sx={{ fontSize: "3rem", color: (theme) => theme.gradient[900] }}
              ></CurrencyExchangeOutlinedIcon>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>ขาดทุน</Typography>
              <Divider></Divider>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>{cashbackProfit} %</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#191207" }}>
          <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            เงื่อนไขในการรับ
          </AlertTitle>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            - ลูกค้าจะต้องไม่กดรับ โบนัส ทุกประเภท
          </Typography>
          <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
            - QR code ใช้ได้เพียงครั้งเดียวเท่านั้น ห้ามใช้ซ้ำ หากสแกนซ้ำเข้ามา ทางเราไม่รับผิดชอบทุกกรณี
          </Typography>
        </Alert>
      </Box> */}

      <SnackbarWithIcon
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
        vertical={vertical}
        horizontal={horizontal}
      />

      {/* <TermsDialog
        cashbackData={cashbackData}
        open={dialogOpen}
        closeDialog={handleCloseDialog}
        getClaimCashback={claimCashback}
      /> */}
    </Grid>
  );
};

export default Cashback;

import { apiPath } from "@/configs/apiPath";
import http from "@/libs/http";
import { handleAxiosError } from "@/libs/utils";

export const getMysteryBoxes = async () => {
  try {
    const { data } = await http.get(apiPath.mysteryBox.mysteryBoxList);
    return data;
  } catch (err) {
    return handleAxiosError(err);
  }
};

export const openMysteryBox = async () => {
  try {
    const { data } = await http.get(apiPath.mysteryBox.mysteryBoxOpen);
    return data;
  } catch (err) {
    return handleAxiosError(err);
  }
};

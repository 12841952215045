import useTransferWallet from "@/hooks/useTransferWallet";
import { formatMoney } from "@/libs/common";
import { LoadingButton } from "@mui/lab";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useEffect } from "react";

type Props = {};

const TransferWallet = (props: Props) => {
  const { user, handleTransfer, fromAndTo, balances, loading, getUfaBalance } =
    useTransferWallet();

  useEffect(() => {
    let i = 1;
    getUfaBalance();
    const interval = setInterval(() => {
      getUfaBalance();
      i++;
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Box width={"100%"}>
      <Divider>
        <Chip
          label={"เข้าเล่น UFA"}
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      <Stack mt={2} spacing={2}>
        <Box>
          <Stack
            spacing={2}
            direction={"row"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
          >
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Chip label="กระเป๋าหลัก" />
              <Typography variant="h5">
                {formatMoney(user?.balance ?? 0)}
              </Typography>
            </Stack>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Chip label="กระเป๋าหลัก UFA" />
              <Typography variant="h5">
                {formatMoney(user?.ufa_balance ?? 0)}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <LoadingButton
          loading={loading}
          onClick={() => handleTransfer("IN", "main", "ufa")}
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            bgcolor: green[500],
            height: "50px",
          }}
        >
          {fromAndTo[0] === "main" && balances[fromAndTo[0]] === 0
            ? "เข้าเล่น UFA"
            : "โยกเงินและเข้าเล่น UFA !"}
        </LoadingButton>
        <LoadingButton
          loading={loading}
          onClick={() => handleTransfer("OUT", "ufa", "main")}
          type="submit"
          variant="contained"
          color="error"
          fullWidth
          sx={{
            bgcolor: red[500],
            height: "50px",
          }}
        >
          โยกเงินออก
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default TransferWallet;

import { usePromotionStore } from "@/stores/promotionStore";
import { useCallback } from "react";
import { shallow } from "zustand/shallow";

const usePromotion = () => {
  const [accept] = usePromotionStore((state) => [state.accept], shallow);

  const handleAccept = useCallback(
    ({
      id,
      onSuccess,
      onError,
    }: {
      id: string | number;
      onSuccess?: (message: string) => void;
      onError?: (message: string) => void;
    }) => {
      accept(String(id))
        .then((res: any) => {
          if (res?.errMessage) {
            onError && onError(res.errMessage);
          } else {
            onSuccess && onSuccess(res.message);
          }
        })
        .catch((err) => {
          onError && onError(err.message);
        });
    },
    [accept]
  );

  return {
    accept: handleAccept,
  };
};

export default usePromotion;

import React, { Fragment } from "react";
import numeral from "numeral";

// mui
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/Button";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

// mui icon
import CloseIcon from "@mui/icons-material/Close";

// type
import { ApiResponseCoupon, ResponsiveDialogProps } from "@/@types/coupon";
import { Box, TextField } from "@mui/material";

// form
import * as yup from "yup";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// fetch
import fetcher from "@/libs/fetcher";
import useMe from "@/hooks/fetchers/useMe";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";

type Inputs = {
  coupon_code: string;
};
const schema = yup.object().shape({
  coupon_code: yup.string().required(),
});
const defaultValues = {
  coupon_code: "",
};
const Coupon: React.FC<ResponsiveDialogProps> = ({ open, closeDialog }) => {
  const { refresh } = useMe();
  const { refresh: refreshPromotion } = usePromotionHistory();
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: Inputs) => {
    const { coupon_code } = data;
    const payload = {
      code: coupon_code,
    };
    const response: ApiResponseCoupon = await fetcher(
      "/api/v1/promotion/receive-coupon",
      { method: "POST", body: payload }
    );
    const status = !response?.status ? "warning" : "success";
    refresh();
    refreshPromotion();
    if (!response?.status) {
      setError("coupon_code", {
        type: "manual",
        message: `${response?.msg}`,
      });
    }
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 1 }}>
          <Grid sx={{ mb: 1, mt: 5 }}>
            <Button variant="contained" color="primary" fullWidth>
              {" "}
              <Typography variant="h5" color="white">
                กรอกคูปอง
              </Typography>
            </Button>
          </Grid>
          <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography sx={{ textAlign: "center", mb: 2 }}>
                  {/* กรอกรหัสคูปอง */}
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name="coupon_code"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        id="coupon_code"
                        label="คูปอง"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        error={Boolean(errors.coupon_code)}
                        placeholder="กรุณากรอกโค้ด"
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                  {errors.coupon_code && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.coupon_code.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <Button
                  disableRipple
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  ยืนยัน
                </Button>
              </CardContent>
            </Card>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default Coupon;

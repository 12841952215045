import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";

type State = {
  openLoginModal: boolean;
  openSignupModal: boolean;
};

type Actions = {
  setOpenLoginModal: (open: boolean) => void;
  setOpenSignupModal: (open: boolean) => void;
};

export const useAuthModalStore = createWithEqualityFn(
  devtools(
    immer<State & Actions>((set) => ({
      openLoginModal: false,
      openSignupModal: false,
      setOpenLoginModal: (open) =>
        set((state) => void (state.openLoginModal = open)),
      setOpenSignupModal: (open) =>
        set((state) => void (state.openSignupModal = open)),
    }))
  ),
  Object.is
);

import { styled, useTheme } from "@mui/material";
import React from "react";
import Link from "next/link";

type Props = {
  src: string;
  height?: number;
  alt?: string;
};

const Logo = ({ src = "", alt = "Logo", height = 70 }: Props) => {
  return (
    <Link href={"/"}>
    <StyledLogo
      sx={{
        height: {
          xs: height - (height * 0.4) + 'px',
          sm: height - (height * 0.2) + 'px',
          md: height + 'px',
          lg: height + 'px',
          xl: height + 'px',
        },
      }}
      src={src}
      alt={"LOGO"}
    />
    </Link>
  );
};

const StyledLogo = styled("img")(({ theme }) => ({
  width: "auto",
  cursor: "pointer",
}));

export default Logo;

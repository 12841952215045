"use client";

import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
} from "@mui/material";
import * as React from "react";

import { ChatRounded, Home } from "@mui/icons-material";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

export interface IAppProps { }

type BottomNavigationAction = {
  path: string;
  label: string;
  icon: React.ReactNode;
};

const bottomNavigationActions: BottomNavigationAction[] = [
  {
    path: "/",
    label: "หน้าแรก",
    icon: <Home />,
  },
  {
    path: "LINE",
    label: "ติดต่อเรา",
    icon: <ChatRounded />,
  },
];

export default function LayoutButtomNavigation(props: IAppProps) {
  const path = usePathname();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [config] = useConfigStore((state) => [state.config], shallow);

  React.useEffect(() => {
    const index = bottomNavigationActions.findIndex(
      (action) => action.path === path
    );
    setActiveIndex(index);
  }, [path]);

  return (
    <Container
      maxWidth="md"
      sx={{
        p: 0,
        m: 0,
      }}
    >
      <BottomNavigation
        sx={{
          background: (theme) => theme.name === 'preset14'
            ? 'linear-gradient(180deg, #FC9ED3, #d566f0)'
            : theme.gradient[400]
        }}
        showLabels
        value={activeIndex}
        onChange={(event, newValue) => {
          setActiveIndex(newValue);
        }}
      >
        {bottomNavigationActions.map((action, index) => (
          <BottomNavigationAction
            component={Link}
            href={
              action.path == "LINE" ? config?.line_oa_url || "" : action.path
            }
            target={action.path == "LINE" ? "_blank" : "_self"}
            key={index}
            label={action.label}
            icon={action.icon}
            sx={{ color: (theme) => theme.gradient[800] }}
          />
        ))}
      </BottomNavigation>
    </Container>
  );
}

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, alpha, Tabs, Tab, useMediaQuery } from "@mui/material";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";


const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
  borderRadius: 16,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  "&:hover": {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.15),
  },
  ...((theme.name === "preset3" || theme.name === "preset6" || theme.name === "preset12" || theme.name === "preset13" || theme.name === "preset15") && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...((theme.name === "preset5") && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...((theme.name === "preset17" || theme.name === "preset23" || theme.name === "preset27") && {
    background: theme.gradient[610],
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
}));

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  // {
  //   key: "recommend",
  //   label: "ล็อบบี้",
  //   icon: "emojione-v1:cherries",
  // },
  {
    key: "slot",
    label: "สล็อต",
    icon: "emojione-v1:slot-machine",
  },
  {
    key: "casino",
    label: "คาสิโน",
    icon: "emojione-v1:joker",
  },
  {
    key: "sport",
    label: "กีฬา",
    icon: "emojione-v1:soccer-ball",
  },
  {
    key: "lottery",
    label: "ล็อตเตอรี่",
    icon: "emojione-v1:pool-8-ball",
  },
  // {
  //   key: "fish",
  //   label: "ยิงปลา",
  //   icon: "logos:glitch-icon",
  // },
];

export default function GameMenu(props: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useRouter();

  const handleMenuClick = (_value: any, index: number) => {
    // console.log(index)

    props.setCategory(index)

    // router.push(`/?category=${key}`);
  };

  return (
    <Box sx={{
      flexGrow: 1,
    }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                md: 'center',
                lg: 'center'
              }
            }} >
            <Tabs
              value={props.category}
              onChange={handleMenuClick}
              variant="scrollable"
              aria-label="scrollable auto tabs example"
              indicatorColor={"none" as any}
              scrollButtons={false}
              // scrollButtons="auto"
              sx={{
                // height: '48px',
                // minHeight: '48px'
              }}
            >
              {menus.map((menu, index) => (
                <Tab
                  icon={
                    <Icon
                      id="icon"
                      icon={menu?.icon || ""}
                      width={24}
                      height={24}
                    />
                  }
                  iconPosition="top"
                  key={menu.key}
                  label={menu.label}
                  sx={{
                    color: props.category === index
                    ? theme.name === 'preset28' ? '#fff' : (theme.gradient && theme.gradient[920]) ? `${theme.gradient[920]} !important` : `${theme.palette.secondary.main} !important`
                    : (theme.gradient && theme.gradient[920]) ? theme.gradient[920] : theme.palette.primary.main,                    
                    background: props.category === index
                      ? theme.gradient[100]
                      : theme.name === 'preset28' ? '#010101' : theme.gradient[400],
                    // height: '48px',
                    // minHeight: '48px',
                    borderRadius: index === 0
                      ? '1rem 0 0 1rem'
                      : menus.length === index + 1
                        ? '0 1rem 1rem 0'
                        : 'initial',
                    ...(isMobile && {
                      minWidth: "60px",
                    }),
                  }}
                />
              ))}
            </Tabs>
          </Item>
        </Grid>
      </Grid>
    </Box>
  )
}

export const randomDelay = (
  minMilliseconds: number = 2000,
  maxMilliseconds: number = 4000
) => {
  const delay =
    Math.floor(Math.random() * (maxMilliseconds - minMilliseconds + 1)) +
    minMilliseconds;

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, delay);
  });
};

"use client";

import { apiPath } from "@/configs/apiPath";
import { fetcherAuth } from "@/libs/utils";
import { useQuery } from "@tanstack/react-query";

type APIResponse = {
  status: boolean | string;
  msg: string;
  data: PlatformWalletItem[];
};

export type PlatformWalletItem = {
  name: string;
  balance: number;
  logo: string;
};

type Result = {
  refetch: Function;
  items: PlatformWalletItem[];
  isLoading: boolean;
  error: Error | null;
};

export default function usePlatformWallets(): Result {
  const { data, error, isLoading, refetch } = useQuery<APIResponse>({
    queryKey: ["platformWallets"],
    // queryFn: () => fetcherAuth(apiPath.auth.platformWallets),
  });

  const items = (data?.data as PlatformWalletItem[]) || [];

  return {
    refetch,
    items,
    isLoading,
    error,
  };
}

"use client";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { green, grey, purple } from "@mui/material/colors";
import { Paper, Stack, useTheme, useMediaQuery } from "@mui/material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import {
  AccountBalanceWallet,
  ArrowBack,
  CurrencyExchange,
  Notifications,
  Person4,
  Refresh,
} from "@mui/icons-material";
import { formatMoney } from "@/libs/common";
import { useRouter, usePathname } from "next/navigation";
import Image from "next/image";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAppModalStore } from "@/stores/appModalStore";
import { useConfigStore } from "@/stores/configStore";
import numeral from "numeral";
import ThemedButton from "../StyledButton";
import useMe from "@/hooks/fetchers/useMe";
import { Logo } from "../logo";
import preset5 from "@/themes/presets/preset5";
import { Icon } from "@iconify/react";
import { useUserProfile } from "@/hooks";
import { LoadingButton } from "@mui/lab";

const pages = ["กิจกรรม/ข่าวสาร", "โปรโมชั่น", "บทความ"];

type RightMenu = {
  key: string;
  label: string;
  variant: "text" | "outlined" | "contained";
  color:
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";
};

const rightMenus: RightMenu[] = [
  {
    key: "login",
    label: "เข้าสู่ระบบ",
    variant: "outlined",
    color: "success",
  },
  {
    key: "register",
    label: "สมัครสมาชิก",
    variant: "contained",
    color: "primary",
  },
];

const settings = [
  { key: "Profile", text: "โปรไฟล์" },
  { key: "changePassword", text: "เปลี่ยนรหัสผ่าน" },
  { key: "historyAll", text: "ประวัติธุรกรรม" },
  { key: "Logout", text: "ออกจากระบบ" },
];

function LayoutAppBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [user, logout] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );
  const isLoggedIn = user != null;
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );
  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const { refresh: refreshMe } = useMe();
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    // user,
    // isBonus,
    // handleOpenModal,
  } = useUserProfile();

  const router = useRouter();
  const pathname = usePathname();

  const showArrowBack = pathname !== "/";

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleArrowBack = () => {
    refreshMe();
    if (pathname.includes("/lobbies")) {
      router.push("/");
    } else {
      router.back();
    }
  };

  const handleRightMenuClick = (key: string) => {
    switch (key) {
      case "login":
        setOpenLoginModal(true);
        break;
      case "register":
        setAppDataModal("signUp");
        setOpenAppModal(true);
        break;
      default:
        break;
    }
  };

  const handleProfileMenuClick = (key: string) => {
    handleCloseUserMenu();
    if (key == "Logout") {
      logout();
    }
    if (key == "Profile" || key == "changePassword" || key == "historyAll") {
      setOpen(true);
      setData(key);
    }
  };

  const current_balance = numeral(user?.balance).format("0,00.00") || 0;

  return (
    <AppBar
      position="fixed"
      variant="elevation"
      elevation={0}
      sx={{
        background: theme.gradient[400],
        ...((theme.name == 'preset5' || theme.name == 'preset23') && {
          // backgroundImage: `url(${theme.backgroundImage.body})`
          backgroundColor: "rgba(0,0,0,0.8)"
        }),
        ...(theme.name == 'preset16' && {
          boxShadow: '#0003 0 4px 6px -1px,#0000001f 0 2px 4px -1px'
        })
      }}
    >
      <Container maxWidth="xl">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            direction={"row"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            {showArrowBack && (
              <IconButton
                style={{
                  border: "1.5px solid white",
                  height: "28px",
                  width: "28px",
                }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleArrowBack}
                color="inherit"
              >
                <ArrowBack style={{ fontSize: "18px" }} />
              </IconButton>
            )}
            <Box py={`${theme.config.logo.appBarSpacing}px`}>
              <Logo
                src={config?.logo || ""}
                height={theme.config.logo.appBarHeight}
              />
            </Box>
          </Stack>
          <Box>
            {!isLoggedIn && (
              <Stack direction={"row"} spacing={1}>
                {rightMenus.map((menu) => (
                  <Button
                    size="large"
                    variant={menu.variant}
                    key={menu.key}
                    color={menu.color}
                    onClick={() => handleRightMenuClick(menu.key)}
                    sx={
                      theme.name === 'preset14'|| theme.name == 'preset33'
                        ? menu.variant === 'outlined'
                          ? {
                            borderColor: '#ffc100',
                            backgroundColor: '#f7ac36',
                            color: '#fff',
                            "&:hover": {
                              borderColor: '#ffc100',
                              backgroundColor: '#f7ac36',
                              color: '#fff',
                            },
                          }
                          : {
                            borderColor: '#33B7FF',
                            backgroundColor: '#33B7ff',
                            color: '#fff',
                            "&:hover": {
                              borderColor: '#33B7FF',
                              backgroundColor: '#33B7ff',
                              color: '#fff',
                            },
                          }
                        : {}
                    }
                  >
                    {menu.label}
                  </Button>
                ))}
              </Stack>
            )}

            {isLoggedIn && (
              <Box display={"flex"}>
                {
                  !isMobile && (
                    <>
                      <Box display="inline" mr={1}>
                        <Box display="flex">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mr={4}
                            sx={{
                              cursor: 'pointer',
                              transition: "all 0.12s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.08)",
                              }
                            }}
                            onClick={() => {
                              setOpen(true)
                              setData('deposit')
                            }}
                          >
                            <Icon
                              id="icon"
                              icon={"noto:dollar-banknote"}
                              width={32}
                              height={32}
                            />
                            <Typography variant="subtitle2" color="white">
                              ฝากเงิน
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            mr={4}
                            sx={{
                              cursor: 'pointer',
                              transition: "all 0.12s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.08)",
                              }
                            }}
                            onClick={() => {
                              setOpen(true)
                              setData('withdraw')
                            }}
                          >
                            <Icon
                              id="icon"
                              icon={"noto:money-bag"}
                              width={32}
                              height={32}
                            />
                            <Typography variant="subtitle2" color="white">
                              ถอน
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )
                }
                {
                  (promotionHistory && currentBalance <= 5) && (
                    <LoadingButton
                      style={{ borderRadius: 16, marginRight: '16px' }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleRemovePro()
                      }}
                      loading={isLoading}
                      sx={{
                        backgroundColor: (theme) => theme.name === 'preset14'
                          ? "#790e50"
                          : "default"
                      }}
                    >
                      ออกจากโปร
                    </LoadingButton>
                  )
                }
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Stack
                        alignItems={"flex-end"}
                        justifyContent={"center"}
                        sx={{ mr: 1 }}
                        spacing={0}
                      >
                        <Typography
                          variant="caption"
                          mb={-1.2}
                          color="white"
                          sx={{
                            ...(promotionHistory &&
                            {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: isMobile ? '90px' : '120px'
                            })
                          }}
                        >
                          {
                            promotionHistory
                              ? isPromotionHistoryLoading
                                ? "กำลังโหลด"
                                : promotionHistory?.promotion.name
                              : "ยอดเงินคงเหลือ"
                          }
                        </Typography>
                        <Typography variant="h6" fontWeight={500} color="white">
                          {current_balance} ฿
                        </Typography>
                      </Stack>
                      <Avatar
                        alt="Remy Sharp"
                        src={
                          promotionHistory
                            ? "https://cdn-icons-png.freepik.com/512/12534/12534332.png"
                            : config?.admin_domain
                              ? config?.admin_domain
                              : "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=1800&t=st=1699245116~exp=1699245716~hmac=4ac516f9219aa13e9a3aa94d4425da97a75882dac9fc8edb557d84310e12047c"}
                      />
                    </Stack>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting?.key}
                      onClick={() => handleProfileMenuClick(setting?.key)}
                    >
                      <Typography textAlign="center">
                        {setting?.text}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Box>
        </Stack>
        {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
        {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".08rem",
              color: "primary.main",
              textDecoration: "none",
            }}
          >
            ทดสอบ
          </Typography> */}
      </Container>
      {/* {user && (
        <Paper square>
          <Container maxWidth="md">
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Person4 color="primary" fontSize="small" />
                <Typography>{user?.username?.toUpperCase()}</Typography>
              </Box>
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
                gap={0.5}
              >
                <AccountBalanceWallet color="primary" fontSize="small" />
                <Typography>{formatMoney(user.point)}</Typography>
                <Refresh color="primary" fontSize="small" />
              </Box>
            </Box>
          </Container>
        </Paper>
      )} */}
    </AppBar>
  );
}
export default LayoutAppBar;

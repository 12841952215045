import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR, { mutate } from "swr";
import { shallow } from "zustand/shallow";

type wheelHistory = {
  type: string;
  gameusername: string;
  credit: number;
  created_at: string;
};

type wheelHistoryReturn = {
  refresh: () => void;
  data: wheelHistory[] | null;
  isLoading: boolean;
  isError: any;
};

export default function useWheelHistory(): wheelHistoryReturn {
  const refresh = () => {
    mutate("/api/v1/wheel/history");
  };
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<wheelHistoryReturn>(
    isLoggedIn ? `/api/v1/wheel/history` : null,
    fetcherAuth
  );
  return {
    refresh,
    data: data?.data || null,
    isLoading: !error && !data,
    isError: error,
  };
}

"use client";

import { GameLauncherDialog } from "@/components/dialog";
import useLaunch from "@/hooks/useLaunch";
import { useEffect, useState } from "react";

const GameLauncher = () => {
  const { gameUrl, setGameUrl, isIframe } = useLaunch();
  const [isExternal, setExternal] = useState(false);
  const open = gameUrl !== "";

  const close = () => {
    setGameUrl("");
  };

  useEffect(() => {
    if (!isIframe && gameUrl !== "") {
      setExternal(true);
      window.open(gameUrl, "_blank");
      setGameUrl("");
    } else {
      setExternal(false);
    }
  }, [isIframe, gameUrl, setGameUrl]);

  if (isExternal) {
    return <></>;
  }

  return <GameLauncherDialog gameUrl={gameUrl} open={open} close={close} />;
};

export default GameLauncher;
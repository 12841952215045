import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  is_lobby: boolean;
  games_lobby: any[];
};

type UseGamesReturn = {
  data: Game[] | null;
  isLoading: boolean;
  isError: any;
};

export default function useGameProviders(code: any): UseGamesReturn {
  const { data, error } = useSWR<Game[]>(
    `/api/v2/users/game?provider=${code}`,
    fetcherAuth
  );
  return {
    data: data || null,
    isLoading: !error && !data,
    isError: error,
  };
}

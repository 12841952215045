import { apiPath } from "@/configs/apiPath";
import { fetcherAuth } from "@/libs/utils";
import { useAuthStore } from "@/stores/authStore";
import useSWR from "swr";
import { shallow } from "zustand/shallow";

type BankAccount = {
  agent_name: string;
  bank_name: string;
  bank_code: string;
  bank_logo: string;
  bank_account_no: string;
  bank_account_name: string;
  promptpay_id?: string;
};

type ApiResponse = {
  status: boolean;
  msg: string;
  data: BankAccount[];
};

type UseBankDepositReturn = {
  data: ApiResponse | null;
  isLoading: boolean;
  isError: any;
};

export default function useBankDeposit(): UseBankDepositReturn {
  const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
  const { data, error } = useSWR<ApiResponse>(
    isLoggedIn ? apiPath.bank.bankList : null,
    fetcherAuth
  );
  return {
    data: data || null,
    isLoading: !error && !data,
    isError: error,
  };
}

"use client";

import * as React from "react";
import { shallow } from "zustand/shallow";
import FormVerifyPassword from "./FormVerifyPassword";
import FormVerifyBankForgotPassword from "./FormVerify";
import { useForgotPasswordStore } from "@/stores/forgotPasswordStore";

const stepContents = [FormVerifyBankForgotPassword, FormVerifyPassword];

export default function StepperContent() {
  const [step] = useForgotPasswordStore((state) => [state.step], shallow);

  const Content = stepContents[step];

  return <Content />;
}
